import React,{Component} from "react";
import TourPricesTable from "../components/component.tour.prices.table";
import CartList from "../components/component.cart.list";
import moment from "moment";
import { NavLink,withRouter } from "react-router-dom";
import DatePicker from "react-datepicker";
import InputSearchHotel from "../components/component.input.search.hotel";
import CreditCardTypes from "../components/component.creditcardtypes.selector";
import VacationCompanies from "../components/component.vacationcompanies.selector";
import ErrorMessages from "../components/component.error.messages";
import Section from '../components/component.section';
import PaymentProcessor from '../components/component.payment.processor.mit';
import Global from '../globals/global';
import ReactResizeDetector from 'react-resize-detector';
import StripesProcessor from '../stripes/App';

const $ = window.$;
const location = window.location;

class checkout extends Global {
  constructor(props) {
    super(props);

    this.checkTerms = this.checkTerms.bind(this);

    
   var paymentprocessorid = this.paymentprocessorid();

   var paymenttypeid = 1;
   var enablepaymenttypes = this.config().enablepaymenttypes

   if(this.grandtotal() == 0) {
     paymenttypeid = 5; //Credit
     enablepaymenttypes=true
   }
    

    this.state = {
      user: {
        name: "",
        lastname: "",
        email: "",
        phone:"",
        useraccountid: -1,
        travelinfo: {
          arrivaldate: new Date(),
          departuredate: new Date(),
          hotelid: -1,
          vacationcompanyname: "",
          vacationcompanyid: -1,
          findaboutus: ""
        },
        paymentinfo: {
          name: "",
          paymenttypeid: paymenttypeid,
          creditcardtypeid: -1,
          creditcardnumber: "",
          expirationmonth: -1,
          expirationyear: -1,
          securitycode: ""
        }
      },
      messages: [],
      processing: false,
      paymentprocessorid: paymentprocessorid,  //2:bancomer MST, 3:MIT, 1:WorldPay, 4: Bnacomer Trafic, 5: Ninguno (reserva sin tarjeta), stip
      acceptTerms: false,
      miturl:false,
      bancomerurl:false,
      hastransportationincluded:0,
      totalhotels:-1,
      itemdestinationid:null,
      enablepaymenttypes:enablepaymenttypes
    };
  }
     paymentprocessorid(){


      var paymentprocessorid = 2;
   
    if(this.getglobaldata('Id_Currency') != 2 ) paymentprocessorid = 2;


  

   if(process.env.REACT_APP_BOOKING_WIDGET == 'bookingwidget3'){

     if(this.getpaymentprocessorid()) paymentprocessorid = this.getpaymentprocessorid()
   }

   

    if(process.env.REACT_APP_BOOKING_WIDGET == 'bookingwidget4'){
    
    if(this.getpaymentprocessorid()) paymentprocessorid = this.getpaymentprocessorid()

    }


    if(process.env.REACT_APP_BOOKING_WIDGET == 'bookingwidget'){
    
    if(this.getpaymentprocessorid()) paymentprocessorid = this.getpaymentprocessorid()

    }

  
      if(this.config().defaultpaymentprocessorid)  paymentprocessorid = this.config().defaultpaymentprocessorid;

      return paymentprocessorid;

     }

    getpaymentprocessorid(){
      
      let paymentprocessorid = null;

      if(this.getglobaldata('TourResellersIds')){

      let destinationinfo = this.getglobaldata('destinationinfo');
      if(this.getglobaldata('TourResellersIds') == '1') {
        paymentprocessorid = 1;
      }
      else if(this.getglobaldata('TourResellersIds') == '2'){
       paymentprocessorid = 2;
     }
    }



    let Cart = this.getglobaldata("Cart");
    let hastransfers = false;
    Cart.map(function (item, index) {
      if(Number(item.itemtype) == 2) hastransfers = true;
    });
     

      if(hastransfers) paymentprocessorid = 1;

    

      return paymentprocessorid;


    }



    gethastransportationincluded(){
         let Cart = this.getglobaldata("Cart");

         if(!Cart) return;
         
         let hastransportationincluded = 0;
         Cart.map(function (item, index) {
          if(Number(item.itemtype) == 1 && Number(item.hastransportationincluded) == -1) hastransportationincluded = -1;

          this.state.itemdestinationid = item.destinationid;

        }.bind(this));

         this.state.hastransportationincluded = hastransportationincluded


         
         let destinationid = Number(this.getglobaldata('Id_Destination') || this.state.itemdestinationid) 
         let currencyid = Number(this.getglobaldata('Id_Currency'))

        if(this.BBVATrafic()){
         if(destinationid == 3 || destinationid == 6) this.state.paymentprocessorid = 1;
         else if(currencyid == 2 || currencyid == 1) this.state.paymentprocessorid = 2;

        
        }
        


    }

    BBVATrafic(){


           if(this.config().defaultpaymentprocessorid){
            return Number(this.config().defaultpaymentprocessorid) == 4
           }

           return this.config().brand == 'trafictours';
    }

  /**
   * Component Did Mount
   * Adding Before Unload Behavior
   **/
   componentDidMount() {

    // this.gethastransportationincluded();

     this.setuser();

     this.getdispatcher('cart.view', function (data) {

     this.updateview()

       
     }.bind(this));

     this.getdispatcher('select.agency', function (data) {
       this.validations();
     }.bind(this));


     this.getdispatcher('has.hotels', function (data) {
      

      this.state.totalhotels = data.detail.totalhotels;
      if(data.detail.totalhotels == 0) {
        this.state.hastransportationincluded = 0;

      }



       this.validations();
     }.bind(this));


     this.changepaymenttype()

   //  this.validations();



     window.addEventListener("message", function(event) {

      

       if(event.data.nbResponse == 'Aprobado'){


        //google analytics event
        this.ga().event({
         category: 'Checkout',
         action: 'Success payment',
         label: 'Success payment'
       });

         $.magnificPopup.close();
         this.successredirect();
       } 

     }.bind(this));

   //  window.onbeforeunload = function() { return  this.t("Sure you want leave the site?"); }.bind(this);

     $(document).ready(function(){
  $('[data-toggle="tooltip"]').tooltip({ trigger: "hover" });   
});

     //Send to Google tag Manager
     this.gtmcheckout();
     
   }



  setuser() {

    if (this.getglobaldata('user')) {
      const user = this.getglobaldata('user');
      this.state.user.name = user.FirstName;
      this.state.user.lastname = user.LastName;
      this.state.user.email = user.Email;
      this.state.user.paymentinfo.name = user.FirstName + ' ' + user.LastName;
      $('input[name="user.name"]').val(this.state.user.name);
      $('input[name="user.lastname"]').val(this.state.user.lastname);
      $('input[name="user.email"]').val(this.state.user.email);
      $('input[name="user.paymentinfo.name"]').val(this.state.user.paymentinfo.name);
    }

  }

  updateview() {


    if(this.grandtotal() == 0) {
     this.state.user.paymentinfo.paymenttypeid = 5; //Credit
     this.state.enablepaymenttypes=true;
   }else {
    this.state.user.paymentinfo.paymenttypeid = 1;
    this.state.enablepaymenttypes= this.config().enablepaymenttypes
  }

  //  this.gethastransportationincluded()

   // this.validations();

    this.changepaymenttype()

    this.forceUpdate();
  }

  grandtotal() {

    if(!this.getglobaldata("Cart")) return 0;

    var Cart = this.getglobaldata("Cart");
    var grandtotal = 0;
    Cart.map(function (item, index) {
      grandtotal = parseFloat(
        parseFloat(grandtotal) + parseFloat(item.total)
      ).toFixed(2);
    });

    return Math.round(grandtotal);
  }

  changeinputform(event) {
    this.changeinput(event);

    var options ={
    callback:this.validations.bind(this),
    clear:true,
    duration:1, //seg
  }
  this.delay(options)
    //this.validations();
  }

  changearrivaldate(date) {
    this.state.user.travelinfo.arrivaldate = date;
    this.validations();
  }

  changedeparturedate(date) {
    this.state.user.travelinfo.departuredate = date;
    this.validations();
  }

  changehotel(hotel) {

    this.state.user.travelinfo.hotelid = hotel.data;

    this.validations();
  }

  changecreditcardtype(value) {
    this.state.user.paymentinfo.creditcardtypeid = value;
    this.validations();
  }

  validations() {
    this.messages();
    this.forceUpdate();
  }

  messages() {
    var user = this.state.user;
    var travelinfo = user.travelinfo;
    var messages = [];

    if (user.name.trim() == "") messages.push(this.t("Enter First Name"));
    if (user.lastname.trim() == "") messages.push(this.t("Enter Last Name"));

    if (this.state.paymentprocessorid == 1) {
      messages = messages.concat(this.paymentinfovalidations());
    }

    if (user.phone.trim() == "") messages.push(this.t("Enter phone number"));

    
    if(this.state.totalhotels >0){
      if (travelinfo.hotelid == -1 && this.state.hastransportationincluded == -1) messages.push(this.t("Select Hotel"));
    }

    /* @if brand = "bookingwidget3" */
      //if (travelinfo.hotelid == -1 && this.state.hastransportationincluded == -1) messages.push(this.t("Select Hotel"));
     // if (travelinfo.hotelid == -1 && this.state.totalhotels >0) messages.push(this.t("Select Hotel"));
    /* @endif */

    /* @if brand = "bookingwidget4" */
     // if (travelinfo.hotelid == -1 && this.state.totalhotels >0) messages.push(this.t("Select Hotel"));
    /* @endif */

     /* @if brand = "bookingwidget2" */
     // if (travelinfo.hotelid == -1 && this.state.hastransportationincluded == -1) messages.push(this.t("Select Hotel"));
    /* @endif */


    /* @if brand = "bookingwidget" */
      //if (travelinfo.hotelid == -1 && this.state.hastransportationincluded == -1) messages.push(this.t("Select Hotel"));
    /* @endif */


   // if (travelinfo.hotelid == -1) messages.push(this.t("Select Hotel"));

    /*if (travelinfo.arrivaldate == "")
      messages.push(this.t("Select Arrival Date"));
    if (travelinfo.arrivaldate == "")
      messages.push(this.t("Select Departure Date"));
    */

    if (!this.isemail(user.email)) messages.push(this.t("Enter a valid email"));
    /*if(ptravelinfo.vacationcompanyid == -1) messages.push(this.t('Select Vacation Company'));
     if(travelinfo.findaboutus == '') messages.push(this.t('Enter Find About Us'));*/


    if(process.env.REACT_APP_BOOKING_WIDGET == 'bookingwidget'){
    if(!this.config().travelagencyid) messages.push(this.t("Select a Agency"));
    }

    /** Removing Before Unload Behavior **/
   /* if(this.messages === undefined || this.messages === '' || this.messages === []){
      window.onbeforeunload = function() { } // Prevent before unload
    } else{
      window.onbeforeunload = function() { return this.t("Sure you want leave the site?"); }
    }*/

    this.state.messages = messages;
  }


  paymentinfovalidations() {
    var user = this.state.user;
    var messages = [];
    var paymentinfo = user.paymentinfo;

    if (paymentinfo.name == "")
      messages.push(
        this.t("Enter Name as it appears on the Credit Card")
      );
    if (paymentinfo.creditcardtypeid == -1)
      messages.push(this.t("Select Credit Card Type"));


    if (!/^[0-9]{16,16}$/i.test(paymentinfo.creditcardnumber) && paymentinfo.creditcardtypeid != 3) messages.push(this.t("Enter credit card number  - 16 Digits"));
    else if (!/^[0-9]{15,15}$/i.test(paymentinfo.creditcardnumber) && paymentinfo.creditcardtypeid == 3) messages.push(this.t("Enter credit card number  - 15 Digits"));



    if (paymentinfo.expirationmonth == -1)
      messages.push(this.t("Select Expiration Month"));
    if (paymentinfo.expirationyear == -1)
      messages.push(this.t("Select Expiration Year"));

    if (!/^[0-9]{3,3}$/i.test(paymentinfo.securitycode) && paymentinfo.creditcardtypeid != 3) messages.push(this.t("Enter Security Code (CCV) - 3 Digits"));
    else if (!/^[0-9]{4,4}$/i.test(paymentinfo.securitycode) && paymentinfo.creditcardtypeid == 3) messages.push(this.t("Enter Security Code (CCV) - 4 Digits"));


 

    return messages;

  }




  submitpayment() {
   // window.onbeforeunload = function() { } // Remove Prevent before unload
    this.validations();

    if (this.state.messages.length > 0) {
      alert(this.t('Please verify your information'));
      return;
    }

    this.state.processing = true;
    this.forceUpdate();
    this.userregister();
  }

  userregister() {


    if(!this.hoteloptons().enable) this.state.user.travelinfo.hotelid = -1;

    var params = {
        username: this.state.user.email,
        LoginType: "NoPassword",
        Email: this.state.user.email,
        password: "",
        FirstName: this.state.user.name,
        LastName: this.state.user.lastname,
        Destination: this.getglobaldata('Id_Destination') || this.state.itemdestinationid,
        Location: this.getglobaldata('Id_Destination') && this.getglobaldata('Id_Location')?this.getglobaldata('Id_Location'):'',
        //ArrivalDate: moment(this.state.user.travelinfo.arrivaldate).format("YYYY-MM-DD"),
        //DepartureDate: moment(this.state.user.travelinfo.departuredate).format("YYYY-MM-DD"),
        Id_Language: this.getglobaldata('Id_Language'),
        AdultsNum: 1,
        ChildrenNum: 0,
        id_hotel: this.state.user.travelinfo.hotelid,
        //Id_VacationCompany: this.state.user.travelinfo.vacationcompanyid,
        YouFind: this.state.user.travelinfo.findaboutus,
        OtherOperator: "",
        Phone:this.state.user.phone
      };

      if(this.config().travelagentid) params.Id_TravelAgent = this.config().travelagentid;
      if(this.config().travelagencyid) params.Id_TravelAgency = this.config().travelagencyid;

     //console.log('params...........',params)
    //return;

    var options = {
      method: "UserRegister",
      body: params,
      callback: function (jsonresponse) {
        this.state.user.useraccountid = jsonresponse.Id_UserAccount;

        this.setuserinfo(jsonresponse);

        this.dobookingcartpayment();
      }.bind(this),
      error: function (jsonresponse) {
        this.state.messages.push(
          this.t(
            "Could not register the account, please try again or contact for help"
          )
        );
        this.state.processing = false;
        this.forceUpdate();
      }.bind(this)
    };

    this.create(options);
  }

  bookingpaymentparams() {

    
    


    var cart = this.getcart();
    var params = {
      ReservationName: this.state.user.name + " " + this.state.user.lastname,
      CreditsRebateUsed: "no",
      Id_PaymentType: this.state.user.paymentinfo.paymenttypeid,
      Id_CreditCardType: this.state.user.paymentinfo.creditcardtypeid,
      CC_Name: this.state.user.paymentinfo.name,
      CC_Number: this.state.user.paymentinfo.creditcardnumber,
      CC_ExpirationMonth: this.state.user.paymentinfo.expirationmonth,
      CC_ExpirationYear: this.state.user.paymentinfo.expirationyear,
      Id_BookingPlatform: this.config().bookingplatformid?this.config().bookingplatformid:4,
      HotelRoomNum: "",
      CC_CCV: this.state.user.paymentinfo.securitycode,
      ConfirmCode: "",
      Comments: "",
      CC_Address: "",
      CC_Id_Country: -1,
      CC_City: "",
      CC_State: "",
      CC_ZipCode: "",
      Id_UserAccount: this.state.user.useraccountid,
      // CurrencyRate: 19, //this.getglobaldata('CurrencyRate'),
      Id_Currency: this.getglobaldata('Id_Currency'),
      HotelUnKnown: this.state.user.travelinfo.hotelid==-1?true:false,
      Id_SBI:this.config().sbiid,
      Cart: JSON.stringify(cart),
      brand:this.config().brand
    }

    if(this.config().mailinfo) params.MailSettings = this.config().mailinfo;

    if(this.config().adminid) params.Id_Admin = this.config().adminid;
    
    if(process.env.REACT_APP_BOOKING_WIDGET == 'bookingwidget'){
     if(this.config().brand == 'indestinationstore') delete params.MailSettings;  
    }

    if(this.config().referrerurl) params.ReferrerUrl= this.config().referrerurl;
   
    return params;
  }

  bancomerprocessorparams() {
   // window.onbeforeunload = function() { } // Remove Prevent before unload
    var originurl = location.origin;
    var hrefurl = location.href;

    if(process.env.REACT_APP_BOOKING_WIDGET == 'bookingwidget'){
    hrefurl = document.referrer;
    }


    if(this.config().referrerurl) hrefurl = this.config().referrerurl;

    var bancomerurl = originurl + (this.config().basename==''?'':"/") + this.config().basename + "/bancomermessage";

    var params = {
      ReferrerUrl: hrefurl,
      Amount: this.grandtotal(),
      Id_Currency: this.getglobaldata('Id_Currency'),
      Id_Language: this.getglobaldata('Id_Language'),
      Cart: this.getcart(),
      UrlOK: bancomerurl + "/success",
      UrlKO: bancomerurl + "/error",
      Id_MobileApp: 2,//2 = Totoura //0  = TRAFICTOURS
      ipnBancomer: this.config().apiurl + 'ipnBancomer',
    }

    if(this.config().brand == 'trafictours') params.Id_MobileApp = 0;

    return params;
  }


  mitprocessorparams() {
   // window.onbeforeunload = function() { } // Remove Prevent before unload
    var originurl = location.origin;
    var hrefurl = location.href;

    if(process.env.REACT_APP_BOOKING_WIDGET == 'bookingwidget'){
    hrefurl = document.referrer;
    }

    if(this.config().referrerurl) hrefurl = this.config().referrerurl;

     var miturl = originurl + (this.config().basename==''?'':"/") + this.config().basename + "/mitmessage";

    var params = {
      ReferrerUrl: hrefurl,
      Id_Currency: this.getglobaldata('Id_Currency'),
      Id_Language: this.getglobaldata('Id_Language'),
      Cart: this.getcart(),
      Id_MobileApp: 2,//Totoura
      amount: this.grandtotal(),
      email: this.state.user.email,
      currency: this.getglobaldata('Id_Currency'),
      UrlOK: miturl + "/success",
      UrlKO: miturl + "/error",
      UrlPE: miturl + "/pending",
    }
    
    return params;
  }

  domitpayment(){
  
    var params = $.extend(this.bookingpaymentparams(), this.mitprocessorparams());

    var options = {
      method: "doMITPayment",
      body: {params:params},
      callback: function (jsonresponse) {

        var miturl = jsonresponse.mit_url_payment.replace('"','');
        
        this.setState({processing:false,miturl:miturl.replace('"','')})
        
        this.openmitpopup();

      }.bind(this),
      error: function (jsonresponse) {
        
        this.state.processing = false;
        this.forceUpdate();
      }.bind(this)
    };

    this.create(options);


    
  }

  dobancomerpaymentv1(){

    var params = $.extend(this.bookingpaymentparams(), this.bancomerprocessorparams());
    
    params.fromajax = true;

    if(this.getglobaldata('user')){
     const user = this.getglobaldata('user');
     params.UserAccessToken = user.UserAccessToken;
     params.DeviceAccessToken = user.DeviceAccessToken;
    }

   var bf = new Buffer(JSON.stringify(params));
   var paramsencoded = bf.toString('base64');

   var bancomerurl = this.config().apiurl+'doBancomerPayment?params='+paramsencoded;

   this.setState({processing:false,bancomerurl:bancomerurl},function(){
    this.createbancomeriframe();
    this.openbancomerpopup();


  }.bind(this))


   
  }

  dobancomerpaymentv2(){
  
    var params = $.extend(this.bookingpaymentparams(), this.bancomerprocessorparams());
    
    params.fromajax = true;
    
    var bf = new Buffer(JSON.stringify(params));
    var paramsencoded = bf.toString('base64');

    var options = {
      method: "doBancomerPaymentV2",
      body: {params:paramsencoded},
      callback: function (jsonresponse) {

        var bancomerurl = jsonresponse.url;
        
       this.setState({processing:false,bancomerurl:bancomerurl},function(){
         this.createbancomeriframe();
         this.openbancomerpopup();
       }.bind(this))


      }.bind(this),
      error: function (jsonresponse) {
        
        this.state.processing = false;
        this.forceUpdate();
      }.bind(this)
    };

    this.create(options);


    
  }

  dobookingcartpayment() {

    if (this.state.paymentprocessorid == 2) {

             if(this.BBVATrafic()) this.dobancomerpaymentv1();
             else this.dobancomerpaymentv2();
    }
    else if(this.state.paymentprocessorid == 3){
          this.domitpayment();
    }
    else{

      this.otherpaymentprocessor();
      
    }

    
  }




  successredirect(){

    this.setglobaldata('Cart', []);
    this.pushnavigate("/myaccount/payment");

  }


  otherpaymentprocessor(){
       
      var options = {
      method: "doBookingCartPayment",
      body: this.bookingpaymentparams(),
      callback: function (jsonresponse) {

         this.successredirect();

      }.bind(this),
      error: function (jsonresponse) {
        this.state.messages.push(this.t(jsonresponse.errorMessage));
        this.state.processing = false;
        this.forceUpdate();
      }.bind(this)
    };


    this.create(options);

  }

  getcart() {
    var globalcart = this.getglobaldata("Cart");

    var cart = [];

    globalcart.map(function (item, index) {
      var item_ = {};



      if (item.itemtype == 1) {
        item_.Id_Destination = item.destinationid;
        item_.ItemType = item.itemtype;
        item_.Id = item.tourid;
        item_.ActivityDate = moment(item.filters.activitydate).format('YYYY-MM-DD');
        item_.ActivityTime = item.filters.activitytime;
        item_.AdultsNum = item.adults.qty;
        item_.KidNum = 0;
        item_.ChildrenNum = item.children.qty;
        item_.ChildFreeNum = item.childrenfree.qty;
        item_.TourExtras = item.extras;
        item_.CouponInfo = {};
        item_.CouponInfo.Name = item.coupon.name;
        item_.CouponInfo.DiscountAmount = item.coupon.discount;
        item_.CouponInfo.Code = item.coupon.code;
        item_.TourExtras = item.filters.extras;
        item_.Id_ActivityRate = item.activityrateid;
        item_.ChildrenAges = item.filters.childrenages.join(',');

      }

        item_.isbZeroCommission = item.commisionzero;
        item_.isZeroCommission =  item.commisiondiscount;
        item_.leadname = item.leadname;

      if (item.itemtype == 2) {
        
        let childrenages = [];

        item.childrenages.map(function(childrenage,index){
             childrenages.push(childrenage.value)
        })

        item_.ChildrenAges = childrenages.join(',');
        item_.ItemType = item.itemtype;
        item_.UserTravelInfo = {
          ArrivalDate: moment(item.arrivalActivityDateTime).format('YYYY-MM-DD'),
          DepartureDate: moment(item.departureActivityDateTime).format('YYYY-MM-DD'),
          DepartureTime: moment(item.departureActivityDateTime).format('HH:mm'),
          ArrivalTime: moment(item.arrivalActivityDateTime).format('HH:mm'),
          Id_ArrivalAirline: item.arrivalairlineid,
          ArrivalFlightNum: item.arrivalflight,
          Id_DepartureAirline: item.departureairlineid,
          DepartureFlightNum: item.departureflight
        };

        item_.TransferParams = {
          Id_Transfer: item.transferid,
          Id_TransferPrice: item.transferpriceid,
          Id_ArrivalAirport: item.arrivalairportid,
          Id_DepartureAirport: item.departureairportid,
          Id_Hotel: item.hotelid,
          Details: {
            AdultsNumber: item.adults.qty,
            KidNum: 0,
            ChildrenNum: item.children.qty,
            ChildFreeNum: item.childrenfree.qty,
            ChildsAdultsNum: "0",
            TripType: item.triptypeid,

            CouponInfo: {
              Id_Coupon: item.coupon.Id_Coupon,
              DiscountAmount: item.coupon.discount,
              Name: item.coupon.name,
              Code: item.coupon.code
            }
          }
        }

      }


      cart.push(item_);
    });

    return cart;
  }

  checkTerms(event) {
    this.setState({
      acceptTerms: event.target.checked
    });
  }


  openmitpopup(){

    $.magnificPopup.open({
      items: {
        src: "#payment_p"
      },
      type: "inline",
      fixedContentPos: true,
      fixedBgPos: true,
      overflowY: 'auto',
      closeBtnInside: true,
      preloader: false,
      midClick: true,
      removalDelay: 300,
      mainClass: 'my-mfp-zoom-in',
      callbacks: {
        open: function() {


        //google analytics event
        this.ga().event({
         category: 'Checkout',
         action: 'Open MIT processor Modal',
         label: 'Open MIT processor Modal'
       });


        }.bind(this),

        close: function() {

        }.bind(this)
      }
    });

  }


  openbancomerpopup(){

    $.magnificPopup.open({
      items: {
        src: "#payment_bancomer"
      },
      type: "inline",
      fixedContentPos: true,
      fixedBgPos: true,
      overflowY: 'auto',
      closeBtnInside: true,
      preloader: false,
      midClick: true,
      removalDelay: 300,
      mainClass: 'my-mfp-zoom-in',
      callbacks: {
        open: function() {


        //google analytics event
        this.ga().event({
         category: 'Checkout',
         action: 'Open BANCOMER processor Modal',
         label: 'Open BANCOMER processor Modal'
       });


        }.bind(this),

        close: function() {

          //this.setState({bancomerurl:false})
          $("#bancomer-iframe").empty();

        }.bind(this)
      }
    });

  }

  /**
   * @function open3dpopup
   **/
  open3dpopup(){

    $.magnificPopup.open({
      items: {
        src: "#securem"
      },
      type: "inline",
      fixedContentPos: true,
      fixedBgPos: true,
      overflowY: 'auto',
      closeBtnInside: true,
      preloader: false,
      midClick: true,
      removalDelay: 300,
      mainClass: 'my-mfp-zoom-in',
      callbacks: {
        open: function() {},

        close: function() {

          //this.state.bancomerurl = false;

        }.bind(this)
      }
    });

  }

  hoteloptons(){
    let options = {
      mandatory:false,
      enable:true
    }

    if(this.state.totalhotels == 0) options.enable = false;

    if(this.state.hastransportationincluded == -1 && this.state.totalhotels > 0) options.mandatory = true;

    if(process.env.REACT_APP_BOOKING_WIDGET == 'bookingwidget2'){

    if(this.state.hastransportationincluded == 0) {
      options.enable = false;
      options.mandatory = false;
    }

    }

    return options;
  }

   onResize(width,height){

      if(this.ismobile()){

       var widthmodalcontent = $('.small-dialog-header').width();
      
        var iframewidth = widthmodalcontent-20;
        
        $('iframe').attr('style','width:'+iframewidth+'px;height:100%');
      }

      //$('.width1').text("("+widthmodalcontent+")")
      //$('.width2').text("("+iframewidth+")")

        return(<div></div>)

    }

    createbancomeriframe(){
   
      
  /*   var iframes = document.querySelectorAll('iframe');
for (var i = 0; i < iframes.length; i++) {
    iframes[i].parentNode.removeChild(iframes[i]);
}*/


      var src = this.state.bancomerurl;
      var ifrm = document.createElement('iframe'); 
      ifrm.setAttribute('src', src); 
      ifrm.setAttribute('allowfullscreen', true); 
      ifrm.setAttribute('frameborder', '0'); 
      ifrm.setAttribute('scrolling', 'no'); 


   
 
      
      $('#bancomer-iframe').empty();
      document.getElementById('bancomer-iframe').appendChild(ifrm);
       
       $('#bancomer-iframe iframe').on("load", function() {
             this.onload();

             
             if(this.ismobile()){
        $('#payment_bancomer').attr('style','height:1850px');
        $('#bancomer-iframe iframe').attr('style', 'height:1800px')
      } 
      else {
        $('#payment_bancomer').attr('style','height:1550px');
        $('#bancomer-iframe iframe').attr('style', 'height:1490px')
      }


       }.bind(this));

       

    }

    changepaymenttype(event){

      //if(!this.state.enablepaymenttypes) return;
    

    let paymenttypeid = this.state.user.paymentinfo.paymenttypeid;

    if(event) if(event.target) paymenttypeid = event.target.value;
    
    if(Number(paymenttypeid) == 1){
      this.state.paymentprocessorid = this.paymentprocessorid()
      this.gethastransportationincluded()
    }else if(Number(paymenttypeid) == 5){
      this.gethastransportationincluded()
      this.state.paymentprocessorid = 5
    }


    this.validations()



    }
  


    onload(){

     console.log('onlaoded');
     this.scrolltop(".mfp-container")

    

    }


  render() {
    var Cart = this.getglobaldata("Cart") || [];
    var user = this.state.user;
    var messages = this.state.messages;
    var paymentprocessorid = this.state.paymentprocessorid;

    var originurl = location.origin;
    var originpath = originurl + (this.config().basename==''?'':"/") + this.config().basename;
    var termsurl = originpath+ "/terms";
    var privacyurl = originpath+ "/privacy";


    if (Cart.length == 0)
      return (
        <Section origin='empty.cart' />
      );

    return (
      <div>
        <Section origin='checkout' />
        {/* End section */}
        <main>
          <div id="position">
            <div className="container">
              <ul>
                <li>
                  <NavLink to="/home">{this.t("Home")}</NavLink>
                </li>
                <li>{this.t("Checkout")}</li>
              </ul>
            </div>
          </div>
          {/* End Position */}
          <div className="container margin_60">
            <div className="row">
              {/* Col-lg-8 */}
              <div
                className="col-lg-8"
                onChange={this.changeinputform.bind(this)}
              >



        

                <button
                  className="btn_category"
                  type="button"
                  data-toggle="collapse"
                  data-target="#collapse_tour"
                  aria-expanded="false"
                  aria-controls="collapse_tour"
                >
                  {this.t("Cart List")}
                </button>
                <div className="collapse show" id="collapse_tour">
                  <div className="card_tours">
                    <div className="tour_container">
                      <div className="row">
                        <div className="col-lg-12">
                          <CartList updateview={this.updateview.bind(this)} />
                        </div>
                      </div>{" "}
                      {/*end row*/}
                    </div>{" "}
                    {/*end tour container*/}
                  </div>
                </div>
                {/*end collapse*/}
                <div className="mt-30" />
                <div className="form_title">
                  <h3>
                    <strong>1</strong>{this.t("Lead Name on Reservation")}
                  </h3>
                  <p>{this.t("Your Details")}</p>
                </div>
                <div className="step">
                  <div className="row">
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label>{this.t("First name")}</label>
                        <span className="text-danger"> *</span>
                        <input
                          type="text"
                          className="form-control"
                          name="user.name"
                        />
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label>{this.t("Last name")}</label>
                        <span className="text-danger"> *</span>
                        <input
                          type="text"
                          className="form-control"
                          name="user.lastname"
                        />
                      </div>
                    </div>


                     

                  </div>
                </div>
                {/*End step */}

                {/*****************************************Start Billing Details *******************************************/}

                {this.state.enablepaymenttypes?
                  <div>

                    <div className="form_title">
                      <h3>
                        <strong>2</strong>{this.t("Payment Information")}
                      </h3>
                    </div>

                     
                    <div className="step">
                      <div className="row">
                      <div className="col-md-6">
                              <div className="form-group">
                              <label>{this.t("Payment type")}</label>
                                <select
                                  className="form-control"
                                  name="user.paymentinfo.paymenttypeid"
                                  value={user.paymentinfo.paymenttypeid}
                                  onChange={this.changepaymenttype.bind(this)}
                                >
                                  <option value={-1} disabled>
                                    {this.t("Select payment type")}
                                  </option>
                                   <option value='1'>
                                    {this.t("Credit Card")}
                                  </option>
                                   <option value='5'>
                                    {this.t("Reserve 24hrs")}
                                  </option>

                                </select>
                              </div>
                            </div>
                        </div>
                        </div>    

                </div>
                :''
                }

                
                {paymentprocessorid == 1 ?
                  <div>
                   
                    <div className="form_title">
                      <h3>
                        {!this.state.enablepaymenttypes?
                          <strong>2</strong>:''
                        }

                         {this.t("Billing Details")}
                      </h3>

                      {!this.state.enablepaymenttypes?<p>{this.t("Payment Information")}</p>:''}
                    </div>
                

                    <div className="step">
                      <div className="form-group">
                        <label>{this.t("Name as it appears on the Credit Card")}</label>
                        <span className="text-danger"> *</span>
                        <input
                          type="text"
                          className="form-control"
                          name="user.paymentinfo.name"
                        />
                      </div>
                      <div className="row">
                        <div className="col-md-6 col-sm-12">
                          <div className="form-group">
                            <label>{this.t("Credit Card Type")}</label>
                            <span className="text-danger"> *</span>

                            <CreditCardTypes
                              name="user.paymentinfo.creditcardtypeid"
                              onChange={this.changecreditcardtype.bind(this)}
                            />
                          </div>
                        </div>
                        <div className="col-md-6 col-sm-12">
                          <img
                            src={this.image("img/cards.png")}
                            width={207}
                            height={43}
                            alt={this.t("Cards")}
                            className="cards"
                          />
                        </div>
                      </div>
                      <div className="form-group">
                        <label>{this.t("Credit Card Number")} </label>
                        <span className="text-danger"> *</span>
                        <input
                          type="text"
                          className="form-control"
                          name="user.paymentinfo.creditcardnumber"
                        />
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          <label>{this.t("Expiration date")}</label>
                          <span className="text-danger"> *</span>
                          <div className="row">
                            <div className="col-md-6">
                              <div className="form-group">
                                <select
                                  className="form-control"
                                  name="user.paymentinfo.expirationmonth"
                                >
                                  <option

                                    selected="selected"
                                    value={-1}
                                  >
                                    {this.t("Month")}
                                  </option>

                                  {this.selectoptions(1, 12, 'ceroleft1-9')}
                                </select>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <select
                                  className="form-control"
                                  name="user.paymentinfo.expirationyear"
                                >
                                  <option value={-1} selected>
                                    {this.t("Year")}
                                  </option>

                                  {this.selectoptions(
                                    moment().format("YYYY"),
                                    moment()
                                      .add(20, "years")
                                      .format("YYYY")
                                  )}
                                </select>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>{this.t("Security code")}</label>
                            <span className="text-danger"> *</span>
                            <div className="row">
                              <div className="col-4">
                                <div className="form-group">
                                  <input
                                    type="text"
                                    name="user.paymentinfo.securitycode"
                                    className="form-control"
                                    placeholder="CCV"
                                  />
                                </div>
                              </div>
                              <div className="col-8">
                                <img
                                  src={this.image("img/icon_ccv.gif")}
                                  width={50}
                                  height={29}
                                  alt="ccv"
                                />
                                <small>{this.t("Last 3 digits")}</small>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/*End row */}
                    </div>
                    {/*End step */}

                  </div>
                  : ''
                }
                {/*End billing details */}



                <div className="form_title">
                  <h3>
                      
                      {paymentprocessorid == 1 || this.state.enablepaymenttypes?
                      <strong>3</strong>:
                      <strong>2</strong>
                      
                      }

                    
                    {this.t("User Info")}
                  </h3>
                  <p>{this.t('Detail Information')}</p>
                </div>
                <div className="step">


                  
                    {this.hoteloptons().enable?
                      <div className="form-group">
                    <label>{this.t("Hotel Name")}</label>
                  

                    {this.hoteloptons().mandatory?<span className="text-danger"> *</span>:''}

                    <InputSearchHotel handle={this.changehotel.bind(this)} />
                  </div>:''}

                 {/*  <div className="row">
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label>{this.t("Arrival Date")}</label>
                        <span className="text-danger"> *</span>
                        <br />

                        <DatePicker
                          selected={user.travelinfo.arrivaldate}
                          minDate={moment()}
                          dateFormat="YYYY-MM-dd"
                          className="form-control"
                          placeholderText={this.t("Arrival Date")}
                          onChange={this.changearrivaldate.bind(this)}
                          disabled={false}
                        />
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label>{this.t("Departure Date")}</label>
                        <span className="text-danger"> *</span>
                        <br />

                        <DatePicker
                          selected={user.travelinfo.departuredate}
                          minDate={moment()}
                          dateFormat="YYYY-MM-dd"
                          className="form-control"
                          placeholderText={this.t("Departure Date")}
                          onChange={this.changedeparturedate.bind(this)}
                          disabled={false}
                        />
                      </div>
                    </div>
                  </div>*/}
                  
                   <div className="form-group">
                    <label>{this.t("Phone")}</label>
                    <span className="text-danger"> *</span>
                    <input
                      type="text"
                      name="user.phone"
                      className="form-control"
                    />
                  </div>

                  <div className="form-group">
                    <label>{this.t("Email")}</label>
                    <span className="text-danger"> *</span>
                    <input
                      type="text"
                      name="user.email"
                      className="form-control"
                    />
                  </div>
                  <hr />
               {/*    <h3>{this.t("Optional")}</h3>
                  <div className="form-group">
                    <label>{this.t("Your Vacation Company")}</label>

                    <VacationCompanies
                      value={user.travelinfo.vacationcompanyid}
                      name="user.travelinfo.vacationcompanyid"
                    />
                  </div>
                  <div className="form-group">
                    <label>{this.t("How did you find about us?")}</label>
                    <input
                      type="text"
                      className="form-control"
                      value={user.travelinfo.findaboutus}
                      name="user.travelinfo.findaboutus"
                    />
                  </div>

                  */}

                  <div className="form-group">
                    <div className="custom-control custom-checkbox">
                      <input type="checkbox" checked={this.state.acceptTerms} className="custom-control-input" id="customCheck1" onClick={this.checkTerms} />
                      <label className="custom-control-label receive_em" for="customCheck1"
                        dangerouslySetInnerHTML={{ __html: this.t('I agree to the <a href="{{termsurl}}" target="_blank">terms & conditions</a>',{termsurl:termsurl}) }}></label>
                    </div>
                  </div>

                  <ErrorMessages messages={messages} />

                   <hr />  

                     
                   
                    {!this.state.processing ?
                      <div className="col-lg-4 col-sm-6">
                    <button
                      style={this.state.acceptTerms ? { display: 'block' } : { display: 'none' }}
                      className="btn checkout_popc"
                      onClick={this.submitpayment.bind(this)}
                    >
                      {this.t("Submit Payment")}
                    </button>
                    </div>
                    :
                    <span>{this.state.processing ? <h5> {this.spinner(this.t('We are processing your request, please wait...'))}</h5> : ''}</span>
                  }
                  

                </div>
                {/*End step */}
                <div id="policy">


                        <ul>
                            <li>{this.t("You will be charged the total amount once your order is confirmed.")}</li>
                            <li>{this.t("If confirmation isn't received instantly, an authorization for the total amount will be held until your booking is confirmed.")}</li>
                            <li>{this.t("You can cancel for free up to 48 hours before the day of the experience, local time.")}</li>                        
                        </ul>

                
                </div>
              </div>
              {/* End col lg-8 */}
              {/* Aside */}
              <aside className="col-lg-4">
                <div className="sidebar-item">
                  <div className="make-me-sticky">
                    <div className="box_style_1">
                      <h3 className="inner">- {this.t("Summary")} -</h3>
                      <table className="table table_summary">
                        <tbody>
                          <tr className="total">
                            <td>
                              {this.t("Grand Total")} {Cart[0].currency}: ${this.grandtotal()}
                            </td>
                          </tr>
                        </tbody>
                      </table>


                      <div className="form-group">
                    <div className="custom-control custom-checkbox">
                      <input type="checkbox" checked={this.state.acceptTerms} className="custom-control-input" id="customCheck2" onClick={this.checkTerms} />
                      <label className="custom-control-label receive_em" for="customCheck2"
                        dangerouslySetInnerHTML={{ __html: this.t('I agree to the <a href="{{termsurl}}" target="_blank">terms & conditions</a>',{termsurl:termsurl}) }}></label>
                    </div>
                  </div>


                      {!this.state.processing ?
                        <a href="javascript:void(0)"
                          style={this.state.acceptTerms ? { display: 'block' } : { display: 'none' }}
                          className="btn checkout_popc"
                          onClick={this.submitpayment.bind(this)}
                        >
                          {this.t("Submit Payment")}
                        </a>
                        :
                        <span>{this.state.processing ? <h5>{this.spinner('...')}</h5> : ''}</span>
                      }

                      <NavLink className="btn_full_outline" to="/cart">
                        <i className="icon-right" /> {this.t("Back")}
                      </NavLink>

                      <br />

                      <ErrorMessages messages={messages} />
                    </div>
                    <div className="box_style_4 lead_text">
                      <p>
                        {this.t("The Lead Name on Reservation must present a valid Photo ID when redeeming their {{brandname}} Voucher(s). Accompanying children do not require Photo ID.",{brandname:this.config().brandname})}
                        <br /> <br />
                        {this.t("This booking will be listed as {{company}} on your credit card statement.",{company:this.state.paymentprocessorid==2 || this.state.paymentprocessorid==3?'Mobile Sail Technologies':'Totoura'})}
                      </p>
                      <p>
                        <a onClick={this.open3dpopup.bind(this)} className="secure_toolt securem_pop secure_brand" data-toggle="tooltip" title={this.t("¿Qué significa 3DSecure?")}>
                        <i className="icon-lock" />
                        {this.t("Secure Transaction Statement")} </a><br/>
                        
                        {this.state.paymentprocessorid==3 || this.state.paymentprocessorid==2?
                          <span>
                        {this.t("Esta transaccion esta protegida con")} <a onClick={this.open3dpopup.bind(this)} className="secure_toolt securem_pop secure_brand" data-toggle="tooltip" title={this.t("¿Qué significa 3DSecure?")}> {this.t("3DSecure, ")}</a>{this.t("nuevo sistema de pago desarrollado por Visa y Mastercard para la realización de compras seguras en Internet que autentifica al comprador como legítimo titular de la tarjeta que está utilizando.")}
                        </span>
                        :''}


                      </p>
                    </div>
                    <div className="box_style_4">
                      <i className="icon_set_1_icon-57" />
                      <h4 dangerouslySetInnerHTML={{ __html: this.t("Need <span className='blue_text'>Help?</span>") }}></h4>
                      <a href={"tel:"+this.config().phone} className="phone">
                        {this.config().phone}
                     </a>
                     <a href={"mailto:"+this.config().email}>{this.config().email}</a><br/>
                      <small>
                        {this.t("Our Vacation Concierge is available.")} <br />
                        {this.t("Everyday from 9:00am to 6:00pm Central")}
                      </small>
                    </div>
                  </div>
                </div>
              </aside>
              {/* End aside */}
            </div>
            {/*End row */}
          </div>
          {/*End container */}


          <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12 no_pad">
                         <div className="details_tour bottom_text">
                           <span dangerouslySetInnerHTML={{ __html:this.t("{{brandname}} facilitates your booking, but a third party experience operator provides the tour/activity directly to you. {{brandname}} does not provide experiences or activities itself. By clicking 'Book Now', you are acknowledging that you have read and are bound by Totoura <a href='{{termsurl}}' target='_blank'>Customer Terms of Use</a>, <a href='{{privacyurl}}' target='_blank'>Privacy Policy</a>, plus the tour operator's rules & regulations.",{termsurl:termsurl,privacyurl:privacyurl,brandname:this.config().brandname})}}></span>
                        </div>
                     </div>
                 </div>

          </div>

          <div id="overlay" />
          {/* Mask on input focus */}
        </main>
        {/* End main */}


         {/* Mit Modal */}
      <div id="payment_p" className="zoom-anim-dialog mfp-hide">
        <div className="small-dialog-header">
            <h3>{this.t('Payment')}</h3>
        </div>
        

         <StripesProcessor Amount={this.grandtotal()}  Currency={Cart[0].currency} /> 
       
        {/* <PaymentProcessor url={this.state.miturl} paymentprocessorid={this.state.paymentprocessorid} />  */}
      </div>
      {/* /Mit Modal */}

    {/* /3D Secure Modal */}
    <div id="securem" className="zoom-anim-dialog mfp-hide">
        <div className="small-dialog-header">
            <h3>3D SECURE</h3>
        </div>
        <div className="tour_container">
            <div className="tour_title">
                <h3><strong>{this.t("¿Que significa 3DSECURE?")}</strong></h3>
                <br />
                <p className="text-justify">{this.t("3D Secure (o 3DS) es un sistema de seguridad para los pagos online con tarjeta bancaria. Este sistema funciona para las tarjetas Visa y MasterCard. El procedimiento de autenticación del titular de la tarjeta es muy sencillo y consta de 3 etapas.")}<br />
            <br/>
        <strong>
        {this.t("- Al realizar la reserva del billete en nuestro sitio web, deben indicarse los datos de la tarjeta.")}<br />  
        {this.t("- Tras la verificación, se abre una ventana 3D Secure: el banco del titular de la tarjeta pide que se introduzcan ciertos datos para verificar si es el propietario de la misma. En la mayoría de los casos, se trata de un código de un solo uso que el banco envía por SMS al teléfono móvil.")}<br />  
        {this.t("- Por último, después de confirmar la etapa anterior*, y de autorizar el pago, se confirma la compra.")}
        <br /><br />  
        {this.t("El pago 3D Secure está disponible en los bancos con el nombre 'Verified by Visa' para las tarjetas Visa y 'MasterCard SecureCode' para las tarjetas MasterCard. Para más información sobre este servicio y los trámites para suscribirse, contacte con su banco.")}</strong>
    <br /><br />
{this.t("* Tres intentos fallidos durante la etapa de autenticación provocan la cancelación de la transacción y el bloqueo de la tarjeta bancaria. En tal caso, le recomendamos contactar con su banco.")}
</p>
            </div>
        <div className="tour_title">
        </div>
        </div>
    </div>
    {/* /3D Secure Modal */}

    {/* Bancomer Modal */}
      <div id="payment_bancomer" className="zoom-anim-dialog mfp-hide">
        <div className="small-dialog-header">
            <h3 >{this.t('Payment')}</h3>
        </div>
        
        <div id='bancomer-iframe'></div>


       
        {/*this.state.bancomerurl?<PaymentProcessor url={this.state.bancomerurl} paymentprocessorid={this.state.paymentprocessorid}  />:''*/}


      </div>
      {/* Bancomer Modal */}






          <ReactResizeDetector handleWidth handleHeight>
            {({ width, height }) => this.onResize(width,height)}
           </ReactResizeDetector>


      </div>
    );
  }
}

export default  withRouter(checkout)
